export const waxServers = [
  "api-wax.eosauthority.com",
  "api.waxsweden.org",
  "chain.wax.io",
  "wax.eosn.io",
  "nftgaming.global.binfra.one",
  "wax.greymass.com",
  "aw-guard.yeomen.ai",
  "wax.greymass.com",
  "wax.pink.gg",
  "wax.eosphere.io",
];
