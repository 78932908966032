import React from "react";
import { Stack, NavDropdown, Nav, Navbar } from "react-bootstrap";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { useAuth } from "../ProvideAuth";

const Main = styled(Stack)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Header = styled(Navbar)`
  background: #1d1d1d !important;
`;

type TProps = {
  children: React.ReactNode;
};

const LayoutSimple = ({ children }: TProps) => {
  const { user, logout } = useAuth();

  if (!user) throw new Error("Dont find user");

  return (
    <Main>
      <Header
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="px-3"
      >
        <Navbar.Brand to="/" as={Link}>
          SmartBot Game
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Link to="/billing" className="btn btn-success">
            Balance: $<strong>{(user?.balance ?? 0).toFixed(2)}</strong>
          </Link>
          <Nav>
            <NavDropdown
              title={user.email}
              menuVariant="dark"
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item onClick={logout}>LogOut</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Header>
      <div className="p-3">{children}</div>
    </Main>
  );
};

export default React.memo(LayoutSimple);
