import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

import { useAuth } from "../../components/ProvideAuth";
import LayoutAuth from "../../components/layouts/Auth";

const SignIn = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const { signInWithEmailAndPassword } = useAuth();

  const login = React.useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      await signInWithEmailAndPassword(email, password);
    } catch (e) {
      const error = e as Error;
      setError(error?.message);
    }

    setLoading(false);
  }, [signInWithEmailAndPassword, email, password]);

  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Form className="p-3">
      <FloatingLabel label="Email address">
        <Form.Control
          type="email"
          placeholder="name@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FloatingLabel>

      <FloatingLabel label="Password" className="mt-3">
        <Form.Control
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FloatingLabel>

      <div className="text-center">
        <Button size="lg" onClick={login} className="mt-3">
          Sign In
        </Button>
      </div>
    </Form>
  );
};

const Login = () => {
  console.log("login");
  return (
    <LayoutAuth>
      <h1 className="fs-1 text-center">Sign in</h1>
      <p className="fs-6 text-muted text-center">
        To access the control panel.
      </p>
      <SignIn />
      <p className="fs-6 text-muted text-center">
        Don't have an account yet? <Link to="/signup">Sign up here</Link>
      </p>
      <p className="fs-6 text-muted text-center">
        Forgot your password?{" "}
        <Link to="/recover-password">Recover password</Link>
      </p>
    </LayoutAuth>
  );
};

export default React.memo(Login);
