import React from "react";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import { Container, Row, Col, Image } from "react-bootstrap";

import gamesList from "../../data/gamesList";

const Wrapper = styled.div`
  background-color: #f4f7f6;
`;

const WrapperForm = styled(Col)`
  background-color: #fff;
`;

const GamesLogoWrapper = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }
`;

type TProps = {
  children: React.ReactNode;
};

const LayoutAuth = ({ children }: TProps) => {
  return (
    <>
      <Global
        styles={css`
          body {
            background-color: #f4f7f6;
          }
        `}
      />

      <Wrapper>
        <Container>
          <Row className="my-5 py-5">
            <Col className="py-5">
              <p className="fs-3">Safe bots to automate your accounts</p>
              <ul>
                <li>They work around the clock, do not allow downtime.</li>
                <li>Provide the maximum profit.</li>
                <li>Completely emulate human actions.</li>
              </ul>
              <Container>
                <Row xs={2} md={4} lg={4} className="justify-content-center">
                  {gamesList.map((gameData) => (
                    <GamesLogoWrapper key={gameData.url} className="p-3">
                      <Image src={gameData.image} />
                    </GamesLogoWrapper>
                  ))}
                </Row>
              </Container>
            </Col>
            <WrapperForm className="py-5">{children}</WrapperForm>
          </Row>
        </Container>
      </Wrapper>
    </>
  );
};

export default React.memo(LayoutAuth);
