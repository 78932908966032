import React from "react";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Alert from "react-bootstrap/Alert";
import { Redirect, Link, useParams, useRouteMatch } from "react-router-dom";

import LayoutMain from "../../components/layouts/Main";
import { useAuth } from "../../components/ProvideAuth";
import gamesList from "../../data/gamesList";

interface IMatchParams {
  accountId?: string;
}

const Game = () => {
  const { user } = useAuth();

  const { gameURL } = useParams<{ gameURL: string; accountId: string }>();

  const match = useRouteMatch<IMatchParams>(
    "/games/:gameURL/accounts/:accountId/"
  );
  const accountId = match?.params?.accountId;

  // Find game by URL
  const game = gamesList.find((gameData) => gameData.url === gameURL);

  if (!game) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  const GameComponent = React.lazy(() => import(`./${game.component}`));

  return (
    <LayoutMain gameURL={game.url}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Games
          </Breadcrumb.Item>
          <Breadcrumb.Item
            active={!accountId}
            linkAs={Link}
            linkProps={{ to: `/games/${game.url}/accounts/` }}
          >
            {game.name}
          </Breadcrumb.Item>
          {accountId && (
            <Breadcrumb.Item
              active={true}
              linkAs={Link}
              linkProps={{ to: `/games/${game.url}/accounts/${accountId}/` }}
            >
              {accountId}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>

        {((user?.balance ?? 0) > 0 && (
          <React.Suspense fallback={<div>Loading...</div>}>
            <GameComponent />
          </React.Suspense>
        )) || (
          <Alert variant="warning">
            To add accounts you first need to{" "}
            <Alert.Link as={Link} to="/billing">
              replenish the balance
            </Alert.Link>
            .
          </Alert>
        )}
      </Container>
    </LayoutMain>
  );
};

export default React.memo(Game);
