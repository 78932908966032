import React from "react";
import {
  BrowserRouter as Router,
  // HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./app.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Login from "./pages/Login";
import Signup from "./pages/Signup";
import NewPassword from "./pages/NewPassword";
import RecoverPassword from "./pages/RecoverPassword";
import Games from "./pages/Games";
import Billing from "./pages/Billing";
import ProvideAuth from "./components/ProvideAuth";
import PrivateRoute from "./PrivateRoute";
import NotAuthRoute from "./NotAuthRoute";

const App = () => {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <NotAuthRoute path="/login">
            <Login />
          </NotAuthRoute>
          <NotAuthRoute path="/signup">
            <Signup />
          </NotAuthRoute>
          <NotAuthRoute path="/recover-password">
            <RecoverPassword />
          </NotAuthRoute>
          <NotAuthRoute path="/new-password">
            <NewPassword />
          </NotAuthRoute>
          <PrivateRoute path={`/billing/`}>
            <Billing />
          </PrivateRoute>
          <PrivateRoute path="/games">
            <Games />
          </PrivateRoute>
          <Route path="/">
            <Redirect to="/games" />
          </Route>
        </Switch>
      </Router>
    </ProvideAuth>
  );
};

export default App;
