import React from "react";
import { Models, ID } from "appwrite";
import { Spinner } from "react-bootstrap";
import { collectionNames, databaseNames } from "@smartbot/config";
import appwrite, { account, databases } from "../utils/appwrite";
import { urlGame } from "../utils/config";

type TAccount = Models.Document & {
  balance: number;
  farmersWorld: {
    usd: number;
  };
};

type TUser = TAccount & {
  name: string;
  email: string;
  $id: string;
};

type TAuthContext = {
  user: TUser | null;
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<void>;
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<void>;
  logout: () => Promise<void>;
  createRecovery: (email: string) => Promise<void>;
  updateRecovery: (
    userId: string,
    secret: string,
    password: string
  ) => Promise<void>;
};

export const authContext = React.createContext<TAuthContext>({
  user: null,
  createUserWithEmailAndPassword: async () => void 0,
  signInWithEmailAndPassword: async () => void 0,
  logout: async () => void 0,
  createRecovery: async () => void 0,
  updateRecovery: async () => void 0,
});

const getUnionUser = async (): Promise<TUser> => {
  const userAccount = await account.get();

  console.log("userAccount", userAccount);

  while (true) {
    try {
      const user = await databases.getDocument<TAccount>(
        "default",
        collectionNames.users,
        userAccount.$id
      );

      console.log("user", user);

      return {
        ...user,
        ...userAccount,
      };
    } catch (e) {
      await new Promise((res) => setTimeout(res, 1 * 1000));
    }
  }
};

const ProvideAuth = ({ children }: { children: React.ReactNode }) => {
  // const [user, loading, error] = useAuthState(auth);
  const [user, setUser] = React.useState<TUser | null>(null);
  const [loading, setLoading] = React.useState(true);
  // const [error, setError] = React.useState<null | string>(null);

  React.useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        setUser(await getUnionUser());
      } catch (e) {
        const error = e as Error;
        console.log("error", error);
        if (error.toString() === "Error: Unauthorized") return;

        // setError(error.toString());
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const signInWithEmailAndPassword = React.useCallback(
    async (email: string, password: string) => {
      await account.createEmailSession(email, password);

      setUser(await getUnionUser());
    },
    []
  );

  const createUserWithEmailAndPassword = React.useCallback(
    async (email: string, password: string) => {
      await account.create(ID.unique(), email, password);
      await signInWithEmailAndPassword(email, password);
    },
    [signInWithEmailAndPassword]
  );

  const logout = React.useCallback(async () => {
    setUser(null);
    await account.deleteSession("current");
  }, []);

  const createRecovery = React.useCallback(async (email: string) => {
    await account.createRecovery(email, `${urlGame}new-password/${email}`);
  }, []);

  const updateRecovery = React.useCallback(
    async (userId: string, secret: string, password: string) => {
      await account.updateRecovery(userId, secret, password, password);
    },
    []
  );

  if (loading)
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

  // if (error) return <div>{error}</div>;

  return (
    <authContext.Provider
      value={{
        user,
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        logout,
        createRecovery,
        updateRecovery,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => {
  const auth = React.useContext(authContext);

  return auth;
};

export default ProvideAuth;
