export const collectionNamesTasks = {
  farmersWorldTasks: "6244d7e35d4568676154",
  dragonsValleyTasks: "625d5bc54bb1fae2944e",
  alienWorldsTasks: "624b12b8dbe4f7e7e935",
  arenaOfGloryTasks: "6268ee334a1bfeb57cc7",
  cryptoFarmsTasks: "6279b0167b3b5b8e4c0c",
  defiMiningTasks: "6281eebe088fc1d4b15e",
  goldMandTasks: "625007659b69fcbaaf0c",
  kryptonQuestTasks: "625e6d8cdc3e8eb8f992",
  NFTPandaTasks: "628b2e84bd0b23c1f394",
  // officeLandTasks: "123",
  spaceCraftXTasks: "626b2283d5400b75e6b0",
  // spaceCryptoTasks: "123",
  // spaceheroesTasks: "123",
  splinterlandsTasks: "624fe823b2364581aaf2",
  // warSpaceTasks: "123",
  xpansionTasks: "626c41ae59513f34c590",
  letMeSpeakTasks: "6284186c41c05bb80cb8",
  gnomeminesTasks: "62b77d3dec6492fd165a",
  realmagnateTasks: "6307d949d732733b3593",
  // redditTasks: "62da7596bf79f456d832",
  // quoraTasks: "631e224b1987a9ba176e",
  sheepFarmTasks: "63720f38871617fcd583",
};

export type TCollectionNamesTasks = typeof collectionNamesTasks;

export const collectionNamesAccounts = {
  farmersWorldAccounts: "62430cffb0e6e20b158f",
  dragonsValleyAccounts: "625d35fcf1ab13613abe",
  alienWorldsAccounts: "624b12a54dee681b86c1",
  arenaOfGloryAccounts: "6268e934229d362abd51",
  cryptoFarmsAccounts: "6279aec4bafb6d48acf1",
  defiMiningAccounts: "627e7a09638e32c5ed5a",
  goldMandAccounts: "624eeccbb9ae618d9747",
  kryptonQuestAccounts: "625e680d7f5e6fbbbdae",
  NFTPandaAccounts: "628b2c00324274ac1a74",
  // officeLandAccounts: "123",
  spaceCraftXAccounts: "626b211ba08c105b110e",
  // spaceCryptoAccounts: "123",
  // spaceheroesAccounts: "123",
  splinterlandsAccounts: "6245fa4d030b62b37be1",
  // warSpaceAccounts: "123",
  xpansionAccounts: "626c3fd06230880b15e5",
  letMeSpeakAccounts: "628363ca05ff12912688",
  gnomeminesAccounts: "62b77c3f463cb9fc141d",
  realmagnateAccounts: "6307d752e94bf6563c28",
  // redditAccounts: "62da72d85a8c09ab1f36",
  // quoraAccounts: "631e21f2b8f8ab80033e",
  sheepFarmAccounts: "63720e0159cf275fad63",
};

export type TCollectionNamesAccounts = typeof collectionNamesAccounts;

export const collectionNamesLogs = {
  farmersWorldLogs: "624b41ec282813bacdc1",
  alienWorldsLogs: "624b12cb394ab22a6903",
  arenaOfGloryLogs: "6268ef287fbc15a3ffd8",
  cryptoFarmsLogs: "6279b0a1b852ac0beb2a",
  defiMiningLogs: "6281efb79d441e0a34f7",
  dragonsValleyLogs: "626a6e19b93232ea57fe",
  goldMandLogs: "62555d99c0e27499b9f0",
  kryptonQuestLogs: "625e8e959553c1a00a88",
  NFTPandaLogs: "628b2fb0d91f89704043",
  // officeLandLogs: "123",
  spaceCraftXLogs: "626b232d2b5c3bfae124",
  // spaceCryptoLogs: "123",
  // spaceheroesLogs: "123",
  splinterlandsLogs: "624fe83cb23259066bd0",
  // warSpaceLogs: "123",
  xpansionLogs: "626c43b056bbb5767d07",
  letMeSpeakLogs: "628419306bb60d3e3f65",
  gnomeminesLogs: "62b77e19e9d612e13d37",
  realmagnateLogs: "6307da63b410b7735144",

  sheepFarmLogs: "6372110987fe22f412b5",
};
export type TCollectionNamesLogs = typeof collectionNamesLogs;

export const collectionsEditor = {
  scenarios: "6322b15e82eb4d053de2",
  triggersSchedule: "6352377e227a414cf643",
  tasksToRunOnSchedule: "6352383565a63667ebcc",
  scenarioTasks: "635238c8eab096ceb0a4",
  scenarioTaskSteps: "63523c96f1f177efabb3",
  scheduleRegularIntervalTimers: "63523ce792fb61eb393d",
  conditionsComparison: "63584ccb5c18fedc56b6",
  relationsModule: "63584ddf4786ec31ac3a",
  telegramBots: "635a1e009f6309712ebc",
  moduleScheduleRegularIntervalTimers: "635849f2ba60bd991a17",
  moduleRandomNumber: "63584b513dfc1b6b18b7",
  moduleRedditLike: "63a54555abeaf9406ae2",
  moduleRedditDislike: "63a546cc89a9ddc2574f",
  moduleRedditRandomPost: "63a549c4c52067635e43",
  moduleRedditComment: "65527ec058a474edd832",
  moduleTelegramSendMessage: "63584bd75b62c8c6ae4c",
  moduleQuoraLike: "63617b82d55874a01503",
  moduleQuoraDislike: "63617bc77c84284266d6",
  moduleQuoraComment: "63aeeacad77744968294",
  moduleQuoraRegAcc: "639d8c1d6c1b5737a242",
  moduleDzenLike: "63dd0a1ac90457fc1a76",
  moduleDzenDislike: "63dd0a25a555e4e2f95d",
  moduleDzenRandomPost: "63e655f262612a5ee8ef",
  moduleDzenComment: "63f4e38012cb45b804c3",
  //
  userProfiles: "636b55fba979b03175d8",
  userProfilesProxies: "636b56909a0dd1260334",
  userProfilesQuoraAccounts: "636b5850ec40f78e7bb0",
  userProfilesRedditAccounts: "63a44a1ededc99e293ff",
  userProfilesDzenAccounts: "63dd1b57f41d756ad71d",
  userProfilesTelegramBots: "123",
  moduleQuoraRandomPost: "638063bc87a8ad22f10c",
  quoraAccounts: "63d21f6915466313a82f",
  // generate article
  userProfilesGenerateArticleAccounts: "6418d458925ee34c617b",
  moduleGenerateArticle: "64057a84561a8e8902fb",
  moduleGenerateArticleOutline: "6418de30bcfe47a20bef",
  moduleGenerateArticleTalkingPoints: "6418e5484d38ed9a6b00",
  moduleGenerateArticleTalkingPoint: "6418e55bebe7090d725f",
  moduleGenerateArticleImages: "644024ed8e2eb7339b6d",
  moduleGenerateArticleConclusion: "644024fac0f593fee7c0",
  moduleGenerateBlogCategories: "6440250a848631964f04",
  moduleGenerateBlogTags: "64402516b12ca453f95b",
  moduleAddImgInArticle: "64949e86081f69b08ff6",
  //
  mobileProxy: "65dd99ee7889ee30ab65",
};

export const databaseNames = {
  Default: "default",
  editor: "6322b1361312170b427a",
};

export const collectionNames = {
  ...collectionNamesTasks,
  ...collectionNamesAccounts,
  ...collectionNamesLogs,
  ...collectionsEditor,
  logs: "123",
  letMeSpeakPersons: "62ace2a328fcd87a84a8",
  letMeSpeakPersonTasks: "62ace4b53967b570a747",
  users: "623e0f06825b9a4999cf",
  proxies: "62534a78aab6201ec3e5",
  proxyLinks: "62534b713c6e46e20d6d",
  tokens: "623c4ac0c6af1ff73574",
  updateDatabaseSplinterAccounts: "62b1ed1001aed59d2f68",
  dailyStatistics: "62b09bb88dd03d153bf1",
  // redditHistory: "62f6250f7c3c2e8abca7",
  // quoraHistory: "631e222f102015edf4a3",
};

export type TCollectionNames = typeof collectionNames;
