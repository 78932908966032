import React from "react";
import { Stack, Button, NavDropdown, Nav, Navbar } from "react-bootstrap";
import styled from "@emotion/styled";
import { Link, useRouteMatch } from "react-router-dom";
import { FiGrid, FiUsers, FiMenu, FiArrowLeft } from "react-icons/fi";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { useAuth } from "../ProvideAuth";

const Wrapper = styled(Stack)`
  min-height: 100vh;
  align-items: flex-start;
`;

const Main = styled(Stack)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Sidebar = styled(ProSidebar)`
  height: 100vh;
`;

const Header = styled(Navbar)`
  background: #1d1d1d !important;
`;

const Title = styled(Link)`
  padding-left: 20px;
  line-height: 55px;
  color: #adadad !important;
  text-decoration: none;
`;

type TProps = {
  children: React.ReactNode;
  gameURL: string;
};

const LayoutMain = ({ children, gameURL }: TProps) => {
  const { user, logout } = useAuth();
  const [toggled, setToggled] = React.useState(false);

  const handleToggledChange = React.useCallback(() => {
    setToggled((checked) => !checked);
  }, []);

  const routeGamesMatch = useRouteMatch({
    path: "/games/:gameURL/",
    strict: true,
  });

  const routeAccountMatch = useRouteMatch({
    path: "/games/:gameURL/accounts/",
    strict: true,
  });

  if (!user) throw new Error("Dont find user");

  return (
    <Wrapper direction="horizontal">
      <Sidebar breakPoint="md" toggled={toggled} onToggle={handleToggledChange}>
        <SidebarHeader>
          <Title className="fs-4 m-0" to="/">
            SmartBot Game
          </Title>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem
              icon={<FiGrid />}
              active={!!routeGamesMatch && routeGamesMatch.isExact}
            >
              Dashboard <Link to={`/games/${gameURL}/`} />
            </MenuItem>
            <MenuItem
              icon={<FiUsers />}
              active={!!routeAccountMatch && routeAccountMatch.isExact}
            >
              Accounts <Link to={`/games/${gameURL}/accounts/`} />
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="circle">
            <MenuItem icon={<FiArrowLeft />}>
              Back To Games
              <Link to="/" />
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </Sidebar>
      <Main>
        <Header
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          className="px-3"
        >
          <Button
            onClick={handleToggledChange}
            variant="light"
            className="d-block d-md-none"
          >
            <FiMenu />
          </Button>
          <Nav className="me-auto"></Nav>
          <Nav>
            <Link to="/billing" className="btn btn-success">
              Balance: $<strong>{(user?.balance ?? 0).toFixed(2)}</strong>
            </Link>
            <NavDropdown title={user.email} menuVariant="dark">
              <NavDropdown.Item onClick={logout}>LogOut</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Header>

        <div className="p-3">{children}</div>
      </Main>
    </Wrapper>
  );
};

export default React.memo(LayoutMain);
