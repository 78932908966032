import farmersWorldImg from "../images/farmers-world.png";
// import pvuImg from "../images/pvu.png";
import tlmImg from "../images/tlm.png";
import nftPanda from "../images/nftpanda.png";
// import spaceheroesImg from "../images/spaceheroes.png";
import spaceCraftXImg from "../images/space-craft-x.png";
import xpansionImg from "../images/xpansion.png";
import bunicornImg from "../images/bunicorn.png";
import blockfarmImg from "../images/blockfarm.png";
import bombCryptoImg from "../images/bomb-crypto.png";
import splinterlandsImg from "../images/splinterlands.png";
import prospectorsImg from "../images/prospectors.png";
import risecityImg from "../images/risecity.png";
import blockchainRPGImg from "../images/blockchain-rpg.png";
import dragonsvalleyImg from "../images/dragonsvalley.png";
import arenaOfGloryImg from "../images/arenaofglory.png";
import officelandImg from "../images/officeland.png";
import goldmandImg from "../images/goldmand.png";
import defiMiningImg from "../images/defi-mining.png";
import warspaceImg from "../images/warspace.png";
import spacecryptoImg from "../images/spacecrypto.png";
import kryptonQuestImg from "../images/krypton.png";
import cryptofarmsImg from "../images/cryptofarms.png";
// // new
// import telegramImg from "../images/telegram.png";
// import discordImg from "../images/discord.png";
// import redditImg from "../images/reddit.png";
// import mediumImg from "../images/medium.png";
// import twitterImg from "../images/twitter.png";
import letMeSpeakImg from "../images/let-me-speak.png";
import gnomeminesImg from "../images/gnomemines.png";
import realmagnateImg from "../images/realmagnate.png";
// import sheepFarmImg from "../images/sheep-farm.png";

const games = [
  // {
  //   name: "Telegram",
  //   image: telegramImg,
  //   url: "telegram",
  //   component: "Telegram",
  // },
  // {
  //   name: "Discord",
  //   image: discordImg,
  //   url: "discord",
  //   component: "Discord",
  // },
  // {
  //   name: "Reddit",
  //   image: redditImg,
  //   url: "reddit",
  //   component: "Reddit",
  // },
  // {
  //   name: "Medium",
  //   image: mediumImg,
  //   url: "medium",
  //   component: "Medium",
  // },
  // {
  //   name: "Twitter",
  //   image: twitterImg,
  //   url: "twitter",
  //   component: "Twitter",
  // },
  // {
  //   name: "Sheep Farm",
  //   image: sheepFarmImg,
  //   url: "sheep-farm",
  //   component: "SheepFarm",
  // },
  {
    name: "RealMagnate",
    image: realmagnateImg,
    url: "real-magnate",
    component: "RealMagnate",
  },

  {
    name: "Splinterlands",
    image: splinterlandsImg,
    url: "splinterlands",
    component: "Splinterlands",
  },
  {
    name: "Farmers World",
    image: farmersWorldImg,
    url: "farmers-world",
    component: "FarmersWorld",
  },
  {
    name: "Alien Worlds",
    image: tlmImg,
    url: "alien-worlds",
    component: "AlienWorlds",
  },
  {
    name: "Krypton Quest",
    image: kryptonQuestImg,
    url: "krypton-quest",
    component: "KryptonQuest",
  },

  // {
  //   name: "Plant vs Undead",
  //   image: pvuImg,
  //   url: "pvu",
  //   // component: "PlantVsUndead",
  // },
  {
    name: "NFT Panda",
    image: nftPanda,
    url: "nft-panda",
    component: "NFTPanda",
  },
  // {
  //   name: "Space Heroes",
  //   image: spaceheroesImg,
  //   url: "space-heroes",
  //   component: "SpaceHeroes",
  // },
  {
    name: "Xpansion",
    image: xpansionImg,
    url: "xpansion",
    component: "Xpansion",
  },
  {
    name: "GoldMand",
    image: goldmandImg,
    url: "goldmand",
    component: "GoldMand",
  },
  {
    name: "DefiMining",
    image: defiMiningImg,
    url: "defi-mining",
    component: "DefiMining",
  },
  {
    name: "GnomeMines",
    image: gnomeminesImg,
    url: "gnome-mines",
    // component: "GnomeMines",
  },
  {
    name: "Space CraftX",
    image: spaceCraftXImg,
    url: "space-craft-x",
    // component: "SpaceCraftX",
  },
  {
    name: "Arena Of Glory",
    image: arenaOfGloryImg,
    url: "arena-of-glory",
    // component: "ArenaOfGlory",
  },
  {
    name: "Dragons Valley",
    image: dragonsvalleyImg,
    url: "dragons-valley",
    // component: "DragonsValley",
  },
  {
    name: "LetMeSpeak",
    image: letMeSpeakImg,
    url: "let-me-speak",
    // component: "LetMeSpeak",
  },
  {
    name: "Crypto Farms",
    image: cryptofarmsImg,
    url: "crypto-farms",
    // component: "CryptoFarms",
  },
  {
    name: "WarSpace",
    image: warspaceImg,
    url: "war-space",
    // component: "WarSpace",
  },
  {
    name: "Office Land",
    image: officelandImg,
    url: "office-land",
    // component: "OfficeLand",
  },
  {
    name: "Block Farm",
    image: blockfarmImg,
    url: "block-farm",
    // component: "BlockFarm",
  },
  {
    name: "Bomb Crypto",
    image: bombCryptoImg,
    url: "bomb-crypto",
    // component: "BombCrypto",
  },
  {
    name: "Prospectors",
    image: prospectorsImg,
    url: "prospectors",
    // component: "Prospectors",
  },
  {
    name: "Rise City",
    image: risecityImg,
    url: "risecity",
    // component: "RiseCity",
  },
  {
    name: "Bunicorn",
    image: bunicornImg,
    url: "bunicorn",
    // component: "Bunicorn",
  },
  {
    name: "Blockchain RPG",
    image: blockchainRPGImg,
    url: "blockchain-rpg",
    // component: "BlockchainRPG",
  },
  {
    name: "SpaceCrypto",
    image: spacecryptoImg,
    url: "space-crypto",
    // component: "SpaceCrypto",
  },
];

export default games;
