import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useAuth } from "../../components/ProvideAuth";
import LayoutSimple from "../../components/layouts/Simple";

const Billing = () => {
  const { user } = useAuth();

  if (!user) throw new Error("Dont find user");

  return (
    <LayoutSimple>
      <Container>
        <Row className="justify-content-center">
          <Col xs lg="6">
            <h1 className="fs-1">Top Up Account</h1>
          </Col>
        </Row>
        <Row className="mt-5 justify-content-center">
          <Col xs lg="6" className="mt-1 text-left">
            <p className="fs-6 text-muted text-left">
              You can deposit your balance manually using USDT and BUSD. To do
              this write us{" "}
              <a
                href="https://t.me/smartbotofficial"
                target="_blank"
                title="t.me/smartbotofficial"
                rel="noreferrer"
              >
                @smartbotofficial
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </LayoutSimple>
  );
};

export default React.memo(Billing);
