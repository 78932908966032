import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Spinner from "react-bootstrap/Spinner";
import { Link, useHistory } from "react-router-dom";

import { useAuth } from "../../components/ProvideAuth";
import LayoutAuth from "../../components/layouts/Auth";

const RecoverPassword = () => {
  const [email, setEmail] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const { createRecovery } = useAuth();
  const history = useHistory();

  const recover = React.useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      await createRecovery(email);
    } catch (e) {
      const error = e as Error;
      setError(error?.message);
    }

    setLoading(false);
    alert("Check your email");
    history.push("/");
  }, [createRecovery, email, history]);

  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Form className="p-3">
      <FloatingLabel label="Email address">
        <Form.Control
          type="email"
          name="email"
          placeholder="name@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FloatingLabel>

      <div className="text-center">
        <Button size="lg" onClick={recover} className="mt-3">
          Recover
        </Button>
      </div>
    </Form>
  );
};

const RecoverPasswordPage = () => {
  return (
    <LayoutAuth>
      <h1 className="fs-1 text-center">Password Recovery</h1>
      <RecoverPassword />
      <p className="fs-6 text-muted text-center">
        Remember your password? <Link to="/signup">Back to sign in</Link>
      </p>
    </LayoutAuth>
  );
};

export default React.memo(RecoverPasswordPage);
