export const projectId =
  process.env.NEXT_PUBLIC_REACT_APP_APPWRITE_PROJECT_ID ||
  "6254191e8556e5a51efe";

export const endpoint =
  process.env.NEXT_PUBLIC_REACT_APP_API_ENDPOINT ||
  "https://v1.api.games.smartbot.black/v1";

export const urlGame =
  process.env.REACT_APP_URL_GAME || "http://localhost:3000/";

export const telegramToken = process.env.TELEGRAM_TOKEN;

export const apiKey = process.env.APPWRITE_API_KEY;

export const amqpLogin = process.env.AMQP_LOGIN;
export const amqpPassword = process.env.AMQP_PASSWORD;
