import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

import { useAuth } from "../../components/ProvideAuth";
import LayoutAuth from "../../components/layouts/Auth";

const NewPassword = () => {
  const [password, setPassword] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const { updateRecovery, signInWithEmailAndPassword } = useAuth();

  const recover = React.useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get("userId");
      const secret = urlParams.get("secret");
      const pathnames = window.location.pathname.split("/");
      const email = pathnames[pathnames.length - 1];
      if (!userId || !secret || !email) throw new Error("Can't get params");
      await updateRecovery(userId, secret, password);
      console.log("email, password", email, password);
      await signInWithEmailAndPassword(email, password);
    } catch (e) {
      const error = e as Error;
      setError(error?.message);
    }
    setLoading(false);
  }, [updateRecovery, password, signInWithEmailAndPassword]);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Form className="p-3">
      {error && (
        <div>
          <p>Error: {error}</p>
        </div>
      )}
      <FloatingLabel label="Password">
        <Form.Control
          type="password"
          value={password}
          onChange={(e) => {
            setError(null);
            setPassword(e.target.value);
          }}
        />
      </FloatingLabel>

      <div className="text-center">
        <Button size="lg" onClick={recover} className="mt-3">
          Update password
        </Button>
      </div>
    </Form>
  );
};

const NewPasswordPage = () => {
  return (
    <LayoutAuth>
      <h1 className="fs-1 text-center">Create new Password</h1>
      <NewPassword />
      <p className="fs-6 text-muted text-center">
        Remember your password? <Link to="/signup">Back to sign in</Link>
      </p>
    </LayoutAuth>
  );
};

export default React.memo(NewPasswordPage);
