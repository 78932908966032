import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import LayoutSimple from "../../components/layouts/Simple";
import gamesList from "../../data/gamesList";
import { useAuth } from "../../components/ProvideAuth";
import Game from "./Game";

const toLowerFirstLetter = (str: string) =>
  str.charAt(0).toLowerCase() + str.slice(1);

const Games = () => {
  const { path } = useRouteMatch();
  const { user } = useAuth();

  return (
    <Switch>
      <Route exact path={path}>
        <LayoutSimple>
          <Container>
            <Row xs={2} md={4} className="g-4">
              {gamesList.map((gameData) => (
                <Col key={gameData.url}>
                  <Card className="text-center">
                    <Card.Img
                      variant="top"
                      className="p-3"
                      src={gameData.image}
                    />
                    <Card.Body>
                      <Card.Title>{gameData.name}</Card.Title>

                      {gameData.component ? (
                        <Link
                          to={`games/${gameData.url}/accounts`}
                          className="btn btn-secondary"
                        >
                          Go to accounts
                        </Link>
                      ) : (
                        <Button disabled variant="light">
                          Support suspended
                        </Button>
                      )}
                    </Card.Body>
                    <Card.Footer>
                      <React.Suspense fallback={<div>Loading...</div>}>
                        <small>
                          Earned $
                          {(gameData.component
                            ? user?.[
                                toLowerFirstLetter(
                                  gameData.component
                                ) as "farmersWorld"
                              ]?.usd || 0
                            : 0
                          ).toFixed(2)}
                        </small>
                      </React.Suspense>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </LayoutSimple>
      </Route>
      <Route path={`${path}/:gameURL`}>
        <Game />
      </Route>
    </Switch>
  );
};

export default React.memo(Games);
